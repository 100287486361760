<template>
    <div class="loginView">
        <a href="/" class="smallLogo"></a>
        <div class="loginViewInner">
            <div style="flex-grow: 1;" class="align-self-center">  
            </div>    
            <div class="align-self-center">
                <b-form @submit="forgotPassword">
                        <b-form-group>
                            <div class="logoContainer">
                                <img src="../assets/img/logo_poolinq_white.svg">
                                <div class="spacer xs"></div>
                                <p>{{ $t('login.renewPassword') }}</p>
                            </div>
                            <div class="spacer xl"></div>
                            <input :placeholder="$t('login.forgotPasswordEmailPlaceholder')" type="email" v-model="email" class="form-control">
                            <div class="spacer m"></div>
                            <b-button class="loginButton" type="submit" variant="primary">{{ $t('login.submitPasswordForgot') }}</b-button>
                            <div class="spacer s"></div>
                            <router-link  class="forgotPassword" to="/login">
                                <span>{{ $t('login.backToLogin') }}</span>
                            </router-link> 
                        </b-form-group>
                        <div class="spacer l"/>
                        <p v-show="done" class="">
                            <i18n path="login.linkWasSend" >
                                <template v-slot:userEmail>
                                    {{ email }} 
                                </template>
                            </i18n>
                        </p>
                        <p v-show="error" class="error">{{ $t('general.errorHasOccured') }}</p>
                        <p class="error" v-if="errorMsg != ''">{{ errorMsg }}</p>
                    </b-form>
            </div>
        </div>   
    </div>    
</template>
<script>
    import DataService from '@/services/StrapiService'
    export default {
        name: 'ForgotPassword',
    
        data() {
            return {
                email: '',
                done: false,
                error: false,
                errorMsg: '',
                strapiUrl: DataService.getServerUrl()
            }
        },
        methods: {
            async forgotPassword(e) {
                e.preventDefault()
                this.done = false;
                this.error = false;
                this.axios.post(this.strapiUrl+'/auth/forgot-password', {
                    email: this.email
                })
                .then(() => {
                    this.done = true
                })
                .catch(error => {
                    console.log(error)
                    this.error = true
                    if (error.response != null && error.response['data'].data != null) {
                      var serverMessage = error.response['data'].data.message[0].messages[0].message
						this.errorMsg = serverMessage
                      if (serverMessage === "This email does not exist.") {
                        this.errorMsg = this.$t('login.errorEmailNotExist')
                      }
                      if (serverMessage === "Please provide a valid email address.") {
                        this.errorMsg = this.$t('login.errorFalseEmail')
                      }
                    }
                })
            }
        }
    }
</script>
<style scoped>
</style>